import { useRef } from 'react'
import './index.scss'
import emailjs from '@emailjs/browser'
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { Icon } from '@iconify/react';

const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('contact_service', 'contact_form', form.current, "bjgCjBHYL-VoPjukA")
            .then(
                () => {
                    alert('Message successfully sent!')
                    window.location.reload(false)
                },
                () => {
                    alert('Failed to send the message, please try again')
                }
            )
    };

    return (
        <>
            <div className='container contact-page'>
                <div className='text-zone'>
                    <h1>
                        Contact Me
                    </h1>
                    <div className='pics'>
                        <ul>
                            <Icon icon='akar-icons:phone' style={{ fontSize: '25px' }}/><b> : +1 (470) 865-2280</b>
                        </ul>
                        <ul>
                            <Icon icon='fontisto:email' style={{ fontSize: '25px' }}/><b> : nathanielk0124@gmail.com</b>
                        </ul>
                    </div>
                    <div className='contact-form'>
                        <form ref={form} onSubmit={sendEmail}>
                            <ul>
                                <li className='half'>
                                    <input type="text" name="name" placeholder="Name" required />
                                </li>
                                <li className='half'>
                                    <input type="email" name="email" placeholder="Email" required />
                                </li>
                                <li>
                                    <input placeholder="Subject" type="text" name="subject" required />
                                </li>
                                <li>
                                    <textarea placeholder="Message" name="message" required />
                                </li>
                                <li>
                                    <input type="submit" className="flat-button" value="SEND" />
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
                <div className='info-map'>
                    Nathaniel Kim,
                    <br />
                    1738 Taylor St NW #B2 <br />
                    Atlanta, GA, 30318 <br />
                    United States <br />
                </div>
                <div className='map-wrap'>
                     <MapContainer center={[33.79375533868981, -84.42880184341655]} zoom={13}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <Marker position={[33.79375533868981, -84.42880184341655]}>
                        </Marker>
                     </MapContainer>
                </div>
            </div>
        </> 
    )
}

export default Contact